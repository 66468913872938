let config
if (process.env.NODE_ENV == 'development') {
    config={
        url: 'http://10.0.1.205:3000/oauth',
    
        params: {
            clientId: '424559b3-19b6-4450-bea2-145a69ca8fb1',
            scopes: ["phone","email","name","surname","patronymic"],
        }
    }
} else {
    config={
        url: 'https://id.ao-nk.ru/oauth',
    
        params: {
            clientId: 'e095965b-a8ed-42ea-94fe-6f12c303f43b',
            scopes: ["phone","email","name","surname","patronymic"],
        }
    }
}
export default config