import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import excel from 'vue-excel-export'

import "./plugins/vuetify-mask.js";

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
if (process.env.NODE_ENV == 'development') {
  Vue.axios.defaults.baseURL = 'http://10.0.1.209:97/v1'
  Vue.axios.defaults.apiURL = 'http://10.0.1.209:97/'
  Vue.axios.defaults.protocol = 'https:'
  Vue.axios.defaults.backDir = 'http://10.0.1.209:97'
  Vue.axios.defaults.profStandUrl = 'http://10.0.1.209:96'
  Vue.axios.defaults.speechHost = window.location.protocol + "//" + window.location.host
} else {
  Vue.axios.defaults.baseURL = "https://" + 'api.sok.ao-nk.ru/v1'
  Vue.axios.defaults.apiURL = 'https://api.sok.ao-nk.ru/'
  Vue.axios.defaults.protocol = "https:"
  Vue.axios.defaults.backDir = "https://" + 'api.sok.ao-nk.ru'
  Vue.axios.defaults.profStandUrl = 'https://psapi.ao-nk.ru'
  Vue.axios.defaults.speechHost = "https://" + window.location.host
}

Vue.config.productionTip = false;

import moment from 'moment-timezone'
import 'moment/locale/ru'
Vue.use(require('vue-moment'), {
  moment
})

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';

Vue.prototype.$checkRole = function (orgId,role) {
  if (Vue.auth.user().isAdmin) {
    return true
  }
  let userRoles = Vue.auth.user().roles.filter(el => el.eduOrgId == orgId).map(el=>el.role)
  if(Array.isArray(role)){
    for(let userRole of userRoles){
      if(role.includes(userRole)){
        return true
      }
      return false
    }
    return false
  } else {
    return userRoles.includes(role)
  }
}
// import vue-panzoom
import panZoom from 'vue-panzoom'

// install plugin
Vue.use(panZoom);
import aonkId from './plugins/aonkId.js';


Vue.prototype.$colorsArray = [
  // 'red',
  // 'orange',
  // 'purple',
  // 'green',
  // 'deep-purple',
  // 'indigo',
  // 'light-blue',
  // 'cyan',
  // 'teal',
  // 'light-green',
  // 'lime',
  // 'yellow',
  // 'amber',
  // 'pink',
  // 'deep-orange',
  // 'brown',

  '#FFCDD2',
  '#BBDEFB',
  '#FFE0B2',
  '#C8E6C9',
  '#E1BEE7',
  '#FFECB3',
  '#F8BBD0',
  '#C5CAE9',
  '#B3E5FC',
  '#B2EBF2',
  '#B2DFDB',
  '#DCEDC8',
  '#F0F4C3',
  '#FFF9C4',
  '#FFCCBC',
  '#D7CCC8',

]
Vue.use(excel)
Vue.use(auth, {
  plugins: {
    http: axios, // Axios
    // http: Vue.http, // Vue Resource
    router: router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    oauth2: {
        aonkId: aonkId,
    }
  },
  options: {
    authRedirect: '/login'
  }
});
new Vue({
  vuetify,
  store,
  router,

  render: (h) => h(App),
}).$mount("#app");
