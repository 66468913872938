import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "index",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          meta: { auth: true },
          name: "StarterPage",
          path: "index",
          component: () => import("@/views/index"),
        },
        {
          name: "Admin",
          meta: { auth: true },
          path: "users",
          component: () => import("@/views/admin/users"),
        },
        {
          name: "list",
          meta: { auth: true },
          path: "edu-org/list",
          component: () => import("@/views/eduOrg/list"),
        },
        {
          name: "view",
          meta: { auth: true },
          path: "edu-org/view/:id",
          component: () => import("@/views/eduOrg/view"),
        },
        {
          name: "progView",
          meta: { auth: true },
          path: "edu-prog/view/:id",
          component: () => import("@/views/eduProg/view"),
          children: [
            {
              name: "info",
              meta: { auth: true },
              path: "info",
              component: () => import("@/views/eduProg/info"),
            },
            {
              name: "edu-stand",
              meta: { auth: true },
              path: "edu-stand",
              component: () => import("@/views/eduProg/eduStandard/index"),
            },
            {
              name: "prof-stand",
              meta: { auth: true },
              path: "prof-stand",
              component: () => import("@/views/eduProg/profStandards/index"),
            },
            {
              name: "disciplines",
              meta: { auth: true },
              path: "disciplines",
              component: () => import("@/views/eduProg/disciplines/index"),
            },
            {
              name: "study",
              meta: { auth: true },
              path: "study",
              component: () => import("@/views/eduProg/study/index"),
            },


          ]
        },
        

        {
          name: "exam",
          path: "tests/admin/:id",
          component: () => import("@/views/tests/index"),
        }
      ],
    },
    {
      meta: { auth: true },
      path: "/revision",
      redirect: "index",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "revisionList",
          meta: { auth: true },
          path: "list",
          component: () => import("@/views/exam/list"),
        },
        {
          name: "theoryExam",
          meta: { auth: true },
          path: "exam/:id",
          component: () => import("@/views/exam/theory"),
        },
        {
          name: "finishedExam",
          meta: { auth: true },
          path: "finish",
          component: () => import("@/views/exam/finished"),
        },
      ]
    },
    {
      meta: { auth: true },
      path: "/employer-area",
      redirect: "index",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "",
          path: "",
          component: () => import("@/views/employer/index"),
        }
      ]
    },
    {
      path: "/login",
      //meta: { auth: false },
      name: "boxedLogin",
      component: () => import("@/views/authentication/BoxedLogin"),
    },
    {
      path: "/employer/:id/:hash",
      name: "employer",
      component: () => import("@/views/authentication/employerLogin"),
    },
    {
      path: "/restore",
      meta: { auth: false },
      name: "restore",
      component: () => import("@/views/authentication/restore"),
    },
    {
      path: "/confirm/:userId/:token",
      meta: { auth: false },
      name: "login",
      component: () => import("@/views/profile/confirm"),
    },
    {
      name: "Error",
      path: "/error",
      component: () => import("@/views/authentication/Error"),
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error")
    }
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
