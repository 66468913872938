<template>
  <v-app   :class="bmiClasses" class="h-100">
    <bmi /> 
      <router-view />
      <v-footer padless fixed height="42" style="background-color: rgb(54, 93, 132);">
            <v-col class="text-center d-flex justify-space-between py-0 white--text align-center" cols="12" :class="$vuetify.breakpoint.mobile ? 'text-caption' : ''">
                <div></div>

                <div>{{ new Date().getFullYear() }} — <strong>Акционерное общество «Национальные квалификации»</strong>
                </div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark v-bind="attrs" v-on="on" @click="$store.state.bmiWindow = true"
                            icon><v-icon >mdi-eye</v-icon></v-btn>
                    </template>
                    <span class="text-h6">Версия для слабовидящих</span>
                </v-tooltip>

            </v-col>
        </v-footer>
  </v-app>

</template>

<script>
import bmi from "@/components/bmi.vue";

export default {
    components: {
        bmi
    },
    computed: {
        bmiClasses() {
            let classes = []
            switch (this.$store.state.bmi.size) {
                case 1: {
                    classes.push('bmi-size-1')
                    break;
                }
                case 2: {
                    classes.push('bmi-size-2')
                    break;
                }
            }
            if (this.$store.state.bmi.invert == 1) {
                classes.push('bmi-invert')
            }
            if (this.$store.state.bmi.grayscale == 1) {
                classes.push('bmi-grayscale')
            }
            if (this.$store.state.bmi.bgOff == 1) {
                classes.push('bmi-bg-off')
            }
            return classes
        }

    },
    data() {
        return {

        }
    }
}
</script>
<style>
.bmi-bg-off {
    background-color: black !important;

}

.bmi-size-1 {
    zoom: 1.1
}

.bmi-size-2 {
    zoom: 1.2
}

.bmi-invert {
    filter: invert(1)
}

.bmi-grayscale {
    filter: grayscale(1)
}
.w-100{
  
    width:100%  !important;
 
}
.h-100{

    height:100%  !important;
 
}
</style>
